// nuxt-ui/stores/webappStore.ts

import type { TwitchInfo } from "~/src/twitch/domain/models/TwitchInfo";
import type { HeaderButtonsSetup } from "~/src/options/domain/models/CmsOptions";
import { useCompetitionStore } from "~/nuxt-ui/stores/competitionStore";
import { useTwitch } from "~/nuxt-ui/Shared/composables/twitch";

export const useWebappStore = defineStore("webappStore", () => {
  const route = useRoute();
  const competitionStore = useCompetitionStore();
  const { getTwitchInfo } = useTwitch();
  const { locale } = useI18n();

  const setActiveRegion = (): string => {
    return route.params.region
      ? (route.params.region as string)
      : competitionStore.region
        ? competitionStore.region.slug
        : "espana";
  };

  const activeRegion = ref<string>(setActiveRegion());
  const regionHovered = ref<string | undefined>(setActiveRegion());
  const navCompetitionHovered = ref<string | undefined>();
  const hoveringMegaMenu = ref<boolean>();
  const twitchInfo = ref<TwitchInfo | undefined>();
  const headerButtonsData = ref<HeaderButtonsSetup | undefined>();

  const updateTwitchStatus = async (): Promise<void> => {
    const region = activeRegion.value === "king-of-kings" ? "espana" : activeRegion.value;
    const twitchData = await getTwitchInfo(region, locale.value);
    if (twitchInfo) {
      twitchInfo.value = twitchData;
    }
  };

  watchEffect(() => {
    if (hoveringMegaMenu.value === false) {
      regionHovered.value = activeRegion.value;
    }
  });

  return {
    activeRegion,
    regionHovered,
    navCompetitionHovered,
    hoveringMegaMenu,
    twitchInfo,
    headerButtonsData,
    setActiveRegion,
    updateTwitchStatus,
  };
});
